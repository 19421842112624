import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from '@coreui/react'
import { parseLanguage } from '../library/utils'
// sidebar nav config
import navigation from './_nav'
import { SET_SIDEBAR } from '../redux/action/actionTypes';
import epsmlabsLogo from '../assets/images/logos.png';
// import mgmLogo from '../assets/images/MGM_LOGO.png';
import { BASEURL } from '../library/Constants'

const TheSidebar = (props) => {


  // setInterval(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Cookie", "csrftoken=BNryDeXWdlvScRbjLlXQUqTarQwIxIJwVzPg1NsLVsy9laUekRNVzCxas5qH3X36");

  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   fetch(`${BASEURL}/autonotifications/sendmail/`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => console.log("result", result))
  //     .catch(error => console.log('error', error));
  // }, 300000);


  const dispatch = useDispatch()
  const show = useSelector(state => state.uiReducer.sidebarShow)
  var nav = parseLanguage(navigation, props.language)
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: SET_SIDEBAR, payload: val })}
      className="bg-color"
    >
      <CSidebarBrand to="/">
        <CImg
          // src={epsmlabsLogo}
          src={epsmlabsLogo}
          className="c-sidebar-brand-full header-logo"
          alt="Logo"
          height={110}
        />
        <CImg
          src={`${process.env.PUBLIC_URL}/avatars/logomini.png`}
          // src={epsmlabsLogo}
          className="c-sidebar-brand-minimized header-logo-small"
          alt="Logo"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
