import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../../assets/images/logos.png";

const DocumentPreview = () => {
  const { encodedLink } = useParams(); // Get the base64 encoded link from the URL
  const [decodedLink, setDecodedLink] = useState("");
  const [blobUrl, setBlobUrl] = useState("");
  const [documentName, setDocumentName] = useState("");

  useEffect(() => {
    // Decode the base64 link
    const decoded = atob(encodedLink);
    setDecodedLink(decoded);

    // Extract the document name from the decoded URL
    const fileName = decoded.substring(decoded.lastIndexOf("/") + 1);
    setDocumentName(fileName);

    // Fetch the document as a blob
    const fetchDocument = async () => {
      try {
        const response = await fetch(decoded);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob) + "#toolbar=0"; // Add parameter to hide toolbar
        setBlobUrl(url);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocument();
  }, [encodedLink]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"space-between", padding: "10px" }}>
        <img src={Logo} height={70} width={90} alt="Logo" style={{ padding: "2px" }} />
        <p style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "20px" }}>
          Document: {documentName}
        </p>
      </div>
      {blobUrl ? (
        <iframe
          src={blobUrl}
          style={{
            width: "100%",
            height: "calc(100vh - 60px)",
            border: "none",
          }}
          title="Document Preview"
        ></iframe>
      ) : (
        <p>Loading document...</p>
      )}
    </div>
  );
};

export default DocumentPreview;
