import React, { Component } from 'react';
import { CButton, CCol, CForm, CRow, CAlert } from '@coreui/react'
import { connect } from 'react-redux';
import { userLogin } from './loginAction';
import LoadingModal from '../../base/modal/LoadingModal';
import { unsetFlashNotification } from '../../../redux/action/uiAction';
import loginImage from '../../../assets/images/vectorImg.png';
import epsumLogo from '../../../assets/images/logos.png';
import digiFolioLogo from '../../../assets/images/FILEGENIX_logo.png';
// import mgmLogo from '../../../assets/images/MGM_LOGO.png';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showPassword: true,
        };
        this.togglePassword = this.togglePassword.bind(this);
    }

    componentDidMount() {
        document.getElementById("year").innerHTML = new Date().getFullYear();
    }

    // login(event) {
    //     event.preventDefault();
    //     this.props.userLogin(event, this.state.username, this.state.password, this.props.history)
    // }

    togglePassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    render() {
        return (
            <>
                {this.props.flashNotification &&
                    <CCol xs="12" md="12" style={{ marginTop: 50, position: "absolute" }}>
                        <CRow>
                            <CCol xs="12" md="6"></CCol>
                            <CCol xs="12" md="6">
                                <CAlert className="alert-top"
                                    color={this.props.flashNotification.color}
                                    show={this.props.flashNotification.visible}
                                    onShowChange={(count) => {
                                        if (count === 0) {
                                            this.props.unsetFlashMessage()
                                        }
                                    }}  >
                                    {this.props.flashNotification.message}
                                </CAlert>
                            </CCol>
                        </CRow>
                    </CCol>
                }
                <main className="backgroundCover main-wrapper">
                    <header>
                        <div className="container-fluid header">
                            <div className="row desktop-view">
                                <div className="col-md-2">
                                    <div className="logo-wrapper">
                                        <img src={digiFolioLogo} alt="kims logo" className="kimsLogoImg" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="title_1 text-center">FILEGENIX</div>
                                    <div className="title_2 text-center">Document Management System</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="logo-wrapper">
                                        <img src={epsumLogo} alt="MGM logo" className="kimsLogoImg" />
                                    </div>
                                </div>
                                {/* <div className="col-md-2">
                                    <div className="logo-wrapper">
                                        <img src={digiFolioLogo} alt="kims logo" className="kimsLogoImg" />
                                    </div>
                                </div> */}
                            </div>
                            <div className="mobile-view">
                                <div className="row">
                                    <div className="col-6" style={{ textAlign: "left" }}>
                                        <img src={digiFolioLogo} alt="kims logo" className="kimsLogoImg" />
                                    </div>
                                    {/* <div className="col-6" style={{ textAlign: "right" }}>
                                        <img src={digiFolioLogo} alt="kims logo" className="kimsLogoImg" />
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title_1 text-center">FILEGENIX</div>
                                        <div className="title_2 text-center">Document Management System</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section>
                        <div className="container-fluid mainContent">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="login-wrap position-relative">
                                        <div className="login-image"></div>
                                        <div className="sign-in-text my-2">Sign In</div>
                                        <div className="loginForm">
                                            <CForm className="text-center" onSubmit={(event) => { this.props.userLogin(event, this.state.username, this.state.password, this.props.history) }}>
                                                <div className="userInput position-relative">
                                                    <i className="fa fa-user icon-position"></i>
                                                    <input type="text" name="username" placeholder={this.props.language.userName} className="inputField shadow-none" autoComplete="username" onChange={event => { this.setState({ username: event.target.value }); }} required />
                                                </div>
                                                <div className="userInput position-relative">
                                                    <i className="fa fa-lock icon-position"></i>
                                                    <input type={`${this.state.showPassword ? "password" : "text"}`} name="password" placeholder="Password" className="inputField shadow-none" autoComplete="current-password" onChange={event => { this.setState({ password: event.target.value }); }} required />

                                                    <a type="button" id="btnToggle" onClick={() => this.togglePassword()}>
                                                        <span className={`fa ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"}`}></span>
                                                    </a>
                                                </div>

                                                <CButton color="primary" type="submit" className="submit" >Login</CButton>
                                                {/* <div className="forgot-password">
                                                    <a align="center">Forgot Password <i className="fa fa-arrow-right"></i></a>
                                                </div> */}
                                            </CForm>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="right-vector-image d-none d-md-block">
                                        <img src={loginImage} alt="vector image" className="loginVectorImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <footer>
                            <div className="container-fluid footer">
                                {/* <hr /> */}
                                {/* <div className="row">
                                    <div className="col-md-6 footerContent" style={{ textAlign: "left" }}>
                                        <span><b>Copyright &copy; <span id="year"></span> </b><a href="https://www.epsumlabs.com/" target="_blank">Epsum Labs Private Limited</a></span>
                                    </div>
                                    <div className="col-md-3 footerContent" style={{ textAlign: "left" }}>
                                        <span><b>All rights reserved.</b></span>
                                    </div>
                                    <div className="col-md-3 footerContent footerLogoSection" style={{ textAlign: "right" }}>
                                        <span><b>Powered by: </b>
                                            <img src={epsumLogo} alt="digifolio logo" className="footerLogo" />
                                        </span>
                                    </div>
                                </div> */}
                                <div>
                                    <div className="footerContent" style={{ float: "left", paddingTop: "4px" }}>
                                        <span><b>Copyright &copy; <span id="year"></span> </b><a href="https://www.epsumlabs.com/" target="_blank">Epsum Labs Private Limited.</a><b> All rights reserved.</b></span>
                                    </div>
                                    <div className="footerContent footerLogoSection" style={{ textAlign: "right" }}>
                                        <span><b>Powered by: </b>
                                            <img src={epsumLogo} alt="digifolio logo" className="footerLogo" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </section>
                    <LoadingModal loading={this.props.loading} language={this.props.language} />
                </main>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loadingReducer.loading,
        language: state.languageTypeReducer.language,
        flashNotification: state.uiReducer.flashNotification,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: (e, username, password, history) => dispatch(userLogin(e, username, password, history)),
        unsetFlashMessage: () => dispatch(unsetFlashNotification()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
