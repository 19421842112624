import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from '@coreui/react'
import { handleNavItemsByPermissions, parseLanguage } from '../library/utils'
// sidebar nav config
import navigation from './nav_bak'

import { SET_SIDEBAR } from '../redux/action/actionTypes';
import epsmlabsLogo from '../assets/images/logos.png';
// import mgmLogo from '../assets/images/MGM_LOGO.png';
import { connect } from 'react-redux'
import { getAllPermissions } from '../views/pages/setup/PermissionAction'

const TheSidebar = (props) => {
  useEffect(() => {
    props.getAllPermissions(props.accessToken)
  }, [props.accessToken])

  const dispatch = useDispatch()
  const show = useSelector(state => state.uiReducer.sidebarShow)

  const allPermissionNames = () => {
    var names = []
    props.permissions.length > 0 && props.permissions.map((item, index) => {
      names.push(item.pk)
    })
    return names
  }

  var customed_nav = [...handleNavItemsByPermissions(navigation, allPermissionNames())]


  var nav = parseLanguage(customed_nav, props.language)

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: SET_SIDEBAR, payload: val })}
      className="bg-color"
    >
      <CSidebarBrand to="/">
        <CImg
          // src={epsmlabsLogo}
          src={epsmlabsLogo}
          className="c-sidebar-brand-full header-logo"
          alt="Logo"
          height={110}
        />
        <CImg
          src={`${process.env.PUBLIC_URL}/avatars/logomini.png`}
          // src={epsmlabsLogo}
          className="c-sidebar-brand-minimized header-logo-small"
          alt="Logo"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

// export default React.memo(TheSidebar)


const mapStateToProps = state => {
  return {
    accessToken: state.userReducer.accessToken,
    language: state.languageTypeReducer.language,
    profile: state.userReducer.profile,
    permissions: state.allPermissionsReducer.permissions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllPermissions: (accessToken) => dispatch(getAllPermissions(accessToken))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TheSidebar));
